import { createContext, PropsWithChildren, useEffect, useContext, useCallback } from "react";
import { Navigate } from "react-router";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { BrowserAuthError, InteractionStatus, InteractionType } from "@azure/msal-browser";
import { Loader } from "../components/Loader";
import { loginRequest } from "../authConfig";

interface TokensResult {
    marketplaceToken: string | null,
    accessToken: string
}

interface AuthContextType {
    getTokens(): Promise<TokensResult>,
    setMarketplaceToken(value: string): void
}

const AuthContext = createContext<AuthContextType>({
    getTokens: () => { throw new Error('Out of the context scope.') },
    setMarketplaceToken: () => { throw new Error('Out of the context scope.') }
});

interface AuthProviderProps extends PropsWithChildren {
}

export function AuthProvider({ children }: AuthProviderProps) {
    const { result, error, acquireToken, login } = useMsalAuthentication(InteractionType.Redirect, loginRequest);
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        console.log('is auth:', isAuthenticated);
        console.log('result:', result);
        console.log('instance:', instance);
        console.log('progress :', inProgress);
        if (error instanceof BrowserAuthError) {
            console.warn('authentication failed:', error);
            login(InteractionType.Redirect, loginRequest);

            return;
        }

        console.log('active account:', instance.getActiveAccount());
        if (!!result?.account && !instance.getActiveAccount()) {
            instance.setActiveAccount(result.account);
            console.log(`Logged in as ${instance.getActiveAccount()!.username}`);
        }
    }, [error, inProgress, instance, isAuthenticated, login, result]);

    const setMarketplaceToken = useCallback((marketplaceToken: string) => {
        sessionStorage.setItem('marketplace_token', marketplaceToken);
    }, []);

    const getTokens = useCallback(async () => {
        const marketplaceToken = sessionStorage.getItem('marketplace_token');
        if (!marketplaceToken) {
            throw new Error('No marketplace token.');
        }

        const result = await acquireToken(InteractionType.Silent);
        if (result === null) {
            throw new Error('Error getting an access token, MsalAuthentication returned null.');
        }

        return {
            marketplaceToken,
            accessToken: result!.accessToken
        };
    }, [acquireToken]);

    if (inProgress === InteractionStatus.None && !!error) {
        return <Navigate to="/authfail" />;
    }

    return (
        <AuthContext.Provider value={{ getTokens, setMarketplaceToken }}>
            <AuthenticatedTemplate>
                {children}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Loader className="mt-20" />
            </UnauthenticatedTemplate>
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(AuthContext);
}
